const Alumni = [
    {
        "Sr No.": 1,
        "Name": "Digvijay Patil",
        "Department": "CSE",
        "Department in Club": "All",
        "Position in Club": "Founder",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "NYU",
        "Contact Number": 9225667893,
        "Email ID": "digvi0987@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/23patildigvijay/",
        "Image": "https://i.imgur.com/h5MRQTj.png"
    },
    {
        "Sr No.": 2,
        "Name": "Mohit Phadtare",
        "Department": "Mech",
        "Department in Club": "All",
        "Position in Club": "Founder",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "University of Nottingham",
        "Contact Number": 9850951013,
        "Email ID": "phadtare.mohit@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/mohit-phadtare/",
        "Image": "https://i.imgur.com/ps3EXLZ.png"
    },
    {
        "Sr No.": 3,
        "Name": "Vedang Patil",
        "Department": "Civil",
        "Department in Club": "All",
        "Position in Club": "Founder",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "UIUC",
        "Contact Number": 7741973427,
        "Email ID": "vedpatil10@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/vedangpatil2000/",
        "Image": "https://i.imgur.com/jKUgfk4.png"
    },
    {
        "Sr No.": 4,
        "Name": "Atharv Ganla",
        "Department": "CSE",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "Northeastern University",
        "Contact Number": 9819366987,
        "Email ID": "atharvganla@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/atharv-ganla-a2b2b916b/",
        "Image": "https://i.imgur.com/ElNiiMd.png"
    },
    {
        "Sr No.": 5,
        "Name": "Swarad Gat",
        "Department": "CSE",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "Stoney Brook",
        "Contact Number": 9871005589,
        "Email ID": "swarad2000@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/swarad-gat-79b507198/",
        "Image": "https://i.imgur.com/pvEN6jo.png"
    },
    {
        "Sr No.": 6,
        "Name": "Rajat Puri",
        "Department": "CSE",
        "Department in Club": "NonTech",
        "Position in Club": "Member",
        "Start Year": 2021,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "HSBC",
        "Contact Number": 9168421264,
        "Email ID": "purirajat.rp@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/rajat-puri-78196a1b2/",
        "Image": "https://i.imgur.com/hxNCnwJ.png"
    },
    {
        "Sr No.": 7,
        "Name": "Varad Choudhari",
        "Department": "Mech",
        "Department in Club": "Mech",
        "Position in Club": "Member",
        "Start Year": 2020,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "Syracuse University",
        "Contact Number": 9766624249,
        "Email ID": "varadc2000@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/varad-choudhari-0a6803180/",
        "Image": "https://i.imgur.com/wFBAhv9.png"
    },
    {
        "Sr No.": 8,
        "Name": "Sohan Vartak",
        "Department": "Mech",
        "Department in Club": "Mech",
        "Position in Club": "Head",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "-",
        "Contact Number": 9422538422,
        "Email ID": "sohanvartak12@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/sohan-vartak-9524961a9/",
        "Image": "https://i.imgur.com/QJ0wCKu.png"
    },
    {
        "Sr No.": 9,
        "Name": "Adya Kanth",
        "Department": "ECE",
        "Department in Club": "NonTech",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "-",
        "Contact Number": 9405905055,
        "Email ID": "adyakanth17@gmail.com",
        "Linkedin": "",
        "Image": "https://i.imgur.com/Kz7id1W.png"
    },
    {
        "Sr No.": 10,
        "Name": "Chanthu Pillai",
        "Department": "ECE",
        "Department in Club": "ECE/NonTech",
        "Position in Club": "Head",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "NiceLimited",
        "Contact Number": 8766473508,
        "Email ID": "chanthupillai26@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/chanthu-sunil-kumar-1593b2a3/",
        "Image": "https://i.imgur.com/pl6V8DJ.png"
    },
    {
        "Sr No.": 11,
        "Name": "Atharva Madiwale",
        "Department": "Mech",
        "Department in Club": "Mech",
        "Position in Club": "Member",
        "Start Year": 2020,
        "End Year": 2022,
        "Current Status": "Undecided",
        "University/Company": "",
        "Contact Number": 8793985016,
        "Email ID": "atharvamadiwale@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/atharva-m-b4861a196/",
        "Image": "https://i.imgur.com/lRuNAzj.png"
    },
    {
        "Sr No.": 12,
        "Name": "Atharwa Pathak",
        "Department": "Mech",
        "Department in Club": "Mech",
        "Position in Club": "Member",
        "Start Year": 2029,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Aker",
        "Contact Number": 9405905055,
        "Email ID": "",
        "Linkedin": "https://www.linkedin.com/in/atharwa-pathak/",
        "Image": "https://i.imgur.com/ClpZOth.png"
    },
    {
        "Sr No.": 13,
        "Name": "Girija Shanware",
        "Department": "Mech",
        "Department in Club": "Mech",
        "Position in Club": "Member",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Tataelectronics",
        "Contact Number": 8805611072,
        "Email ID": "girija200034@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/girija-shanware-a8a491147/",
        "Image": "https://i.imgur.com/Ibc9cxj.png"
    },
    {
        "Sr No.": 14,
        "Name": "Kishita Pakhrani",
        "Department": "Robotics and Automation",
        "Department in Club": "Mech/ECE/Nontech",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "NYU/Northeastern",
        "Contact Number": 9033306666,
        "Email ID": "pakhranikishita00@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/kishitapakhrani/",
        "Image": "https://i.imgur.com/fzSESUE.png"
    },
    {
        "Sr No.": 15,
        "Name": "Kushika Bahuguna",
        "Department": "Electrical",
        "Department in Club": "ECE",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Deloitte",
        "Contact Number": 9665039441,
        "Email ID": "kushikabahuguna99@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/kushika-bahuguna-61810a1b9/",
        "Image": "https://i.imgur.com/Kz7id1W.pngg"
    },
    {
        "Sr No.": 16,
        "Name": "Manik Dhawan",
        "Department": "ECE",
        "Department in Club": "ECE",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "Undecided",
        "Contact Number": 9872716542,
        "Email ID": "dhawanmanik21@gmail.com",
        "Linkedin": "",
        "Image": "https://i.imgur.com/Kz7id1W.png"
    },
    {
        "Sr No.": 17,
        "Name": "Mohit Patro",
        "Department": "ECE",
        "Department in Club": "Design",
        "Position in Club": "Head",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Job/Masters",
        "University/Company": "",
        "Contact Number": 7756853007,
        "Email ID": "mohit.patro2000@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/mohit-patro2903/",
        "Image": "https://i.imgur.com/2k7L0Oz.png"
    },
    {
        "Sr No.": 18,
        "Name": "Poojan Panchal",
        "Department": "CSE",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2021,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "USC",
        "Contact Number": 8698669859,
        "Email ID": "pdavpoojan@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/poojan-panchal/",
        "Image": "https://i.imgur.com/3R11kgc.png"
    },
    {
        "Sr No.": 19,
        "Name": "Vignesh Charan",
        "Department": "CSE",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2020,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "USC",
        "Contact Number": 8087814138,
        "Email ID": "vigneshcharan26@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/vignesh-charan/",
        "Image": "https://i.imgur.com/xAPfAjh.png"
    },
    {
        "Sr No.": 20,
        "Name": "Rushal Pedram",
        "Department": "Mech",
        "Department in Club": "Mech",
        "Position in Club": "Member",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Undecided",
        "University/Company": "",
        "Contact Number": 8999965409,
        "Email ID": "pedramrushal12345@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/rushal-pedram-7809a6ab/",
        "Image": "https://i.imgur.com/Kz7id1W.png"
    },
    {
        "Sr No.": 21,
        "Name": "Shubhasmita Roy",
        "Department": "ECE",
        "Department in Club": "ECE",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Persistent",
        "Contact Number": 8208862922,
        "Email ID": "shubhasmitar@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/shubhasmita-roy-858209191/",
        "Image": "https://i.imgur.com/1IgZTyd.png"
    },
    {
        "Sr No.": 22,
        "Name": "Vedant Modi",
        "Department": "CSE",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2021,
        "End Year": 2022,
        "Current Status": "Masters",
        "University/Company": "USC",
        "Contact Number": 7798096024,
        "Email ID": "vedantmodi267@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/vedant-modi/",
        "Image": "https://i.imgur.com/E57LJTU.png"
    },
    {
        "Sr No.": 23,
        "Name": "Sanskar Garodiya",
        "Department": "CSE",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2020,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Pfizer",
        "Contact Number": 7425833402,
        "Email ID": "sanskargarodia05@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/sanskar-garodia-879753170/",
        "Image": "https://i.imgur.com/ghxKQal.png"
    },
    {
        "Sr No.": 24,
        "Name": "Deven Bapat",
        "Department": "Mech",
        "Department in Club": "Mechanical",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "",
        "Contact Number": 9604290113,
        "Email ID": "devendineshbapat@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/deven-bapat-85b37b154/",
        "Image": "https://i.imgur.com/lgcjnW5.png"
    },
    {
        "Sr No.": 25,
        "Name": "Pallavi Mahajan",
        "Department": "CSE",
        "Department in Club": "Non tech",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Vodafone",
        "Contact Number": 9665336331,
        "Email ID": "pmmahajan15@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/pallavi-mahajan-b50b9720b/",
        "Image": "https://i.imgur.com/kyYPIqU.png"
    },
    {
        "Sr No.": 26,
        "Name": "Kaushik Singh",
        "Department": "Robotics and Automation",
        "Department in Club": "Mechanical and Robotics",
        "Position in Club": "Member",
        "Start Year": 2020,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Merkel Sokrati",
        "Contact Number": 9021026867,
        "Email ID": "kaushiksingh28@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/kaushiksingh28/",
        "Image": "https://i.imgur.com/WehLORI.png"
    },
    {
        "Sr No.": 27,
        "Name": "Kunjal Wadke",
        "Department": "ECE",
        "Department in Club": "ECE",
        "Position in Club": "Member",
        "Start Year": 2020,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Accenture",
        "Contact Number": 9860849634,
        "Email ID": "kunjalwadke8888@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/kunjal-wadke-11790a212/",
        "Image": "https://i.imgur.com/Kz7id1W.png"
    },
    {
        "Sr No.": 28,
        "Name": "Prateek Parbat",
        "Department": "Mech",
        "Department in Club": "Mech",
        "Position in Club": "Member",
        "Start Year": 2019,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "-",
        "Contact Number": 8459842101,
        "Email ID": "prateekparbat@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/kunjalw99/",
        "Image": "https://i.imgur.com/Nj7ICf2.png"
    },
    {
        "Sr No.": 29,
        "Name": "Gaurav Sarawgi",
        "Department": "CSE/Passed out in 2021",
        "Department in Club": "Non Tech",
        "Position in Club": "Head",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Tieto Evry",
        "Contact Number": 9424820930,
        "Email ID": "sarawgigaurav@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/gaurav-sarawgi-316711173/",
        "Image": "https://i.imgur.com/BJsmGLD.png"
    },
    {
        "Sr No.": 30,
        "Name": "Chinmay",
        "Department": "CSE/Passed out in 2021",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "Tieto Evry",
        "Contact Number": 9926163620,
        "Email ID": "chinmayambolikar@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/chinmayambolikar/",
        "Image": "https://i.imgur.com/AuWEGIf.png"
    },
    {
        "Sr No.": 31,
        "Name": "Hrishikesh Salunke",
        "Department": "CSE/Passed out in 2021",
        "Department in Club": "CSE",
        "Position in Club": "Member",
        "Start Year": 2018,
        "End Year": 2022,
        "Current Status": "Job",
        "University/Company": "HSBC",
        "Contact Number": 9834906088,
        "Email ID": "salunkhehrushi1@gmail.com",
        "Linkedin": "https://www.linkedin.com/in/hrushikesh-salunkhe1/",
        "Image": "https://i.imgur.com/Kz7id1W.png"
    }
]

export default Alumni;
